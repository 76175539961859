import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  combineModuleVersions,
  transformModuleServersResponse,
} from 'dto/modules/deployModules';
import {
  IAvailableModuleServer,
  IDeployModuleSearchResponse,
  IDeployModuleServersResponse,
} from 'interfaces/modules/module.interface';
import { consoleErrorMessage } from 'utils/commonFunctions/CommonFunctions';
import { Useuid } from 'utils/hooks/useUid';

const UNEXPECTED_ERROR_MESSAGE = 'An unexpected error occurred';

export const fetchAvailableModules = createAsyncThunk(
  'manageModules/fetchAvailableModules',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetch('/csb/modules/available', {
        method: 'GET',
        credentials: 'include',
      });
      if (!response.ok) {
        throw new Error(response.statusText ?? UNEXPECTED_ERROR_MESSAGE);
      }
      const data: IAvailableModuleServer = await response.json();
      return data;
    } catch (error) {
      consoleErrorMessage(error);
      return rejectWithValue(error);
    }
  }
);

export const fetchAvailableModulesWithoutLoading = createAsyncThunk(
  'manageModules/fetchAvailableModulesWithoutLoading',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetch('/csb/modules/available', {
        method: 'GET',
        credentials: 'include',
      });
      if (!response.ok) {
        throw new Error(response.statusText ?? UNEXPECTED_ERROR_MESSAGE);
      }
      const data: IAvailableModuleServer = await response.json();
      return data;
    } catch (error) {
      consoleErrorMessage(error);
      return rejectWithValue(error);
    }
  }
);

export interface IfetchModulesCandidates {
  artifactId: string;
  version?: string;
}
export const fetchModulesCandidates = createAsyncThunk(
  'manageModules/fetchModulesCandidates',
  async (props: IfetchModulesCandidates, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `/csb/modules/candidates/${props.artifactId}?version=${props.version}`,
        {
          method: 'GET',
          credentials: 'include',
        }
      );
      if (response.status !== 200) {
        return rejectWithValue(response.statusText ?? UNEXPECTED_ERROR_MESSAGE);
      }
      const data: IDeployModuleSearchResponse[] = await response.json();
      return combineModuleVersions(data);
    } catch (error) {
      consoleErrorMessage(error);
      return rejectWithValue(error);
    }
  }
);

export const fetchEC2Servers = createAsyncThunk(
  'manageModules/fetchEC2Servers',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetch(`/csb/modules/available/servers`, {
        method: 'GET',
        credentials: 'include',
      });
      if (!response.ok) {
        throw new Error(response.statusText ?? UNEXPECTED_ERROR_MESSAGE);
      }
      const data: IDeployModuleServersResponse = await response.json();
      return transformModuleServersResponse(data);
    } catch (error) {
      consoleErrorMessage(error);
      return rejectWithValue(error);
    }
  }
);
